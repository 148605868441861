<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="w-full space-y-7">
        <div class="card p-3">
          <nav class="responsive-nav -mb-0.5 lg:pl-2">
            <div class="flex justify-between flex-col-reverse lg:flex-row">
              <nav class="responsive-nav pl-2 is_ligh -mb-0.5 border-transparent">
                <ul>
                  <li :class="{'active': status === 'pending'}"><a href="#" v-on:click="status = 'pending'">Đang chờ<span>{{ pendingCount }}</span></a></li>
                  <li :class="{'active': status === 'not_sure'}"><a href="#" v-on:click="status = 'not_sure'">Chưa chắc chắn<span>{{ notSureCount }}</span></a></li>
                  <li :class="{'active': status === 'approved'}"><a href="#" v-on:click="status = 'approved'">Đồng ý<span>{{ approvedCount }}</span></a></li>
                  <li :class="{'active': status === 'rejected'}"><a href="#" v-on:click="status = 'rejected'">Từ chối<span>{{ rejectedCount }}</span></a></li>
                </ul>
              </nav>
            </div>
          </nav>

          <form class="mt-4" v-on:submit.prevent="updateBatchUpload">
            <div class="flex space-x-4">
              <input v-model="q" type="text" class="form-control shadow-none with-border"/>
              <select v-model="action" class="btn-group bootstrap-select shadow-none with-border">
                <option value="">-- Thao tác --</option>
                <option value="approve">Đồng ý</option>
                <option value="reject">Từ chối</option>
                <option value="reset">Reset trạng thái</option>
                <option value="not_sure">Chưa chắc chắn</option>
                <option value="delete">Xóa</option>
              </select>
              <button :disabled="!selectedUploadIds.length || !action" type="submit"
                      class="hover:bg-gray-300 bg-gray-200 rounded-md whitespace-nowrap px-4">Thực hiện <span
                  v-if="selectedUploadIds.length">({{ selectedUploadIds.length }})</span></button>
            </div>
          </form>

          <div class="lg:mt-8 mt-2">
            <table class="w-full" v-if="uploads.data && uploads.data.length">
              <thead>
              <tr class="border-b border-slate-200">
                <th class="flex align-items-center">
                  <div class="checkbox">
                    <input type="checkbox" id="checkbox" v-model="isCheckedAll"/>
                    <label for="checkbox"><span class="checkbox-icon"></span></label>
                  </div>
                </th>
                <th class="py-2 pr-2">Nội dung</th>
              </tr>
              </thead>
              <tbody class="divide-y">
              <upload-item v-on:approveUpload="handleApproveUpload" 
                           v-on:rejectUpload="handleRejectUpload"
                           v-on:notSureUpload="handleNotSureUpload"
                           v-on:deleteUpload="handleDeleteUpload"
                           v-on:checkedUpload="handleCheckedUpload" 
                           :upload="upload"
                          v-for="upload in uploads.data" :key="'uploads' + upload.id"/>
              </tbody>
            </table>

            <div class="mt-4">
              <v-pagination
                  class="justify-content-center"
                  v-if="uploads.paginatorInfo.lastPage > 1"
                  v-model="uploads.paginatorInfo.currentPage"
                  :pages="uploads.paginatorInfo.lastPage"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="loadUploads"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="approve-upload-modal" class="create-post is-story" uk-modal>
      <div
          class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

        <div class="text-center py-3 border-b">
          <h3 class="text-lg font-semibold">Đồng ý bản upload</h3>
          <button id="approve-upload-modalClose" class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close
                  uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>

        <div class="px-5 py-2" v-if="currentUpload">
          Bạn có chắc muốn Đồng ý bản upload <strong>{{ currentUpload.title ? currentUpload.title : currentUpload.link }}</strong>?
        </div>

        <div class="flex items-center w-full justify-center border-t p-3">
          <a href="javascript:void(0)" v-on:click="approveUpload"
             class="bg-green-600 hover:bg-green-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
             Đồng ý</a>
        </div>
      </div>
    </div>

    <div id="not_sure-upload-modal" class="create-post is-story" uk-modal>
      <div
          class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

        <div class="text-center py-3 border-b">
          <h3 class="text-lg font-semibold">Chưa chắc chắn bản upload</h3>
          <button id="not_sure-upload-modalClose" class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close
                  uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>

        <div class="px-5 py-2" v-if="currentUpload">
          Bạn có muốn đánh dấu bản upload <strong>{{ currentUpload.title ? currentUpload.title : currentUpload.link }}</strong> là chưa chắc chắn?
        </div>

        <div class="flex items-center w-full justify-center border-t p-3">
          <a href="javascript:void(0)" v-on:click="notSureUpload"
             class="bg-gray-600 hover:bg-gray-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
             Đúng, chưa chắc chắn</a>
        </div>
      </div>
    </div>

    <div id="reject-upload-modal" class="create-post is-story" uk-modal>
      <div
          class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

        <div class="text-center py-3 border-b">
          <h3 class="text-lg font-semibold">Từ chối bản upload</h3>
          <button id="reject-upload-modalClose" class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close
                  uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>

        <div class="px-5 py-2">
          <textarea v-model="reason" class="shadow-none with-border" placeholder="Lý do từ chối" id="reason"></textarea>
        </div>

        <div class="flex items-center w-full justify-center border-t p-3">
          <a href="javascript:void(0)" v-on:click="rejectUpload"
             class="bg-green-600 hover:bg-green-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
            Từ chối</a>
        </div>
      </div>
    </div>

    <div id="delete-upload-modal" class="create-post is-story" uk-modal>
      <div
          class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

        <div class="text-center py-3 border-b">
          <h3 class="text-lg font-semibold">Xóa bản upload</h3>
          <button id="delete-upload-modalClose" class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close
                  uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>

        <div class="px-5 py-2" v-if="currentUpload">
          Bạn có chắc muốn xóa bản upload <strong>{{ currentUpload.title ? currentUpload.title : currentUpload.link }}</strong>?
        </div>

        <div class="flex items-center w-full justify-center border-t p-3">
          <a href="javascript:void(0)" v-on:click="deleteUpload"
             class="bg-red-600 hover:bg-red-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
             Xóa</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../core/services/api.service";
import UploadItem from "./UploadItem";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {canManageUpload, changePageTitle, closeModal} from "../../../core/services/utils.service";

export default {
  name: "AdminUploadList",
  components: {UploadItem, VPagination},
  data() {
    return {
      q: "",
      action: "",
      isCheckedAll: false,
      status: "pending",
      uploads: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      pendingCount: 0,
      notSureCount: 0,
      approvedCount: 0,
      rejectedCount: 0,
      selectedUploadIds: [],
      currentUpload: null,
      reason: ""
    }
  },
  methods: {
    loadUploads() {
      let where = {
        AND: [
          {column: "status", value: this.status}
        ]
      }

      if (this.q) {
        where.AND.push({column: "title", value: this.q + "%", operator: "LIKE"});
      }

      let query = `query($page: Int, $where: WhereConditions) {
        uploads(first:20, where: $where, page: $page, orderBy: [{column: "created_at", order:DESC}]) {
          data {
            id
            title
            content
            link
            note
            created_at
            status
            type
            composers
            artists
            poets
            recomposers
            fcats
            melodies
            result
            reason
            user {
              id
              username
            }
            processor {
              id
              username
            }
            thumbnail {
              url
            }
            file {
              audio_url
              video_url
              type
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {where: where, page: this.uploads.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.uploads) {
              this.uploads = data.data.uploads;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadPendingCount() {
      let query = `query {
        uploads(first:1, where: {AND: [{column: "status", value: "pending"}]}) {
          paginatorInfo {
            total
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.uploads && data.data.uploads.paginatorInfo) {
              this.pendingCount = data.data.uploads.paginatorInfo.total;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadNotSureCount() {
      let query = `query {
        uploads(first:1, where: {AND: [{column: "status", value: "not_sure"}]}) {
          paginatorInfo {
            total
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.uploads && data.data.uploads.paginatorInfo) {
              this.notSureCount = data.data.uploads.paginatorInfo.total;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadApprovedCount() {
      let query = `query {
        uploads(first:1, where: {AND: [{column: "status", value: "approved"}]}) {
          paginatorInfo {
            total
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.uploads && data.data.uploads.paginatorInfo) {
              this.approvedCount = data.data.uploads.paginatorInfo.total;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadRejectedCount() {
      let query = `query {
        uploads(first:1, where: {AND: [{column: "status", value: "rejected"}]}) {
          paginatorInfo {
            total
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.uploads && data.data.uploads.paginatorInfo) {
              this.rejectedCount = data.data.uploads.paginatorInfo.total;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    updateBatchUpload() {
      if (!this.selectedUploadIds.length || !this.action) {
        return;
      }

      if (confirm("Bạn có chắc muốn thực hiện thao tác này?")) {
        let query = `mutation($ids: [ID!], $action: String!) {
          processBatchUpload(ids: $ids, action: $action)
        }`;
        
        ApiService.graphql(query, {ids: this.selectedUploadIds, action: this.action})
            .then(({data}) => {
              if (data.data && data.data.processBatchUpload) {
                this.selectedUploadIds = [];
                this.reRender();
              } else {
                alert(data.errors[0].message);
              }
            })
            .catch((response) => {
              alert(response.message);
            });
      }
    },
    handleCheckedUpload(upload, isChecked) {
      if (isChecked) {
        if (!this.selectedUploadIds.includes(upload.id)) {
          this.selectedUploadIds.push(upload.id);
        }
      } else {
        this.selectedUploadIds = this.selectedUploadIds.filter((item) => {
          return item !== upload.id;
        });
      }
    },
    approveUpload() {
      this.processUpload(this.currentUpload, "approve");
    },
    notSureUpload() {
      this.processUpload(this.currentUpload, "not_sure");
    },
    rejectUpload() {
      this.processUpload(this.currentUpload, "reject", this.reason);
    },
    deleteUpload() {
      this.processUpload(this.currentUpload, "delete");
    },
    processUpload(upload, action, reason) {
      let query = `mutation($id: ID!, $action: String!, $reason: String) {
        processUpload(id: $id, action: $action, reason: $reason) {
          id
          status
        }
      }`;

      ApiService.graphql(query, {id: upload.id, action: action, reason: reason})
          .then(({data}) => {
            if (data.data && data.data.processUpload) {
              this.reRender();
              if (action === 'reject') {
                this.reason = "";
              }
              closeModal(action + "-upload-modal");
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    handleApproveUpload(upload) {
      this.currentUpload = upload;
    },
    handleRejectUpload(upload) {
      this.currentUpload = upload;
    },
    handleNotSureUpload(upload) {
      this.currentUpload = upload;
    },
    handleDeleteUpload(upload) {
      this.currentUpload = upload;
    },
    reRender() {
      this.loadUploads();
      this.loadPendingCount();
      this.loadNotSureCount();
      this.loadApprovedCount();
      this.loadRejectedCount();
    }
  },
  mounted() {
    if (!canManageUpload()) {
      this.$router.push({name: "Home"});
      return;
    }
    this.reRender();
    changePageTitle("Quản lý upload");
  },
  watch: {
    q: {
      handler() {
        this.uploads.paginatorInfo.currentPage = 1;
        this.loadUploads();
      }
    },
    status: {
      handler() {
        this.uploads.paginatorInfo.currentPage = 1;
        this.loadUploads();
      }
    },
    isCheckedAll: {
      handler(newVal) {
        this.uploads.data.map((uploads) => {
          uploads.is_checked = newVal;
        });
      }
    },
    "uploads.data": {
      handler(newVal) {
        this.selectedUploadIds = [];
        newVal.map((uploads) => {
          if (uploads.is_checked) {
            this.selectedUploadIds.push(uploads.id);
          }
        });
      },
      deep: true
    },
  }
}
</script>
